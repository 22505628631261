import { useEffect, useState, useRef } from 'react'
import Popup from 'reactjs-popup'
import MarkdownText from '../MarkdownText/MarkdownText'
import CommunityButtons from './CommunityButtons/CommunityButtons'
import CommunityModal from './CommunityModal/CommunityModal'
import './style.scss'

interface ICommunityCardProps {
  title: string
  author: string
  date: string
  code: string
  paper?: string
  description: string
}

const CommunityCard = (props: ICommunityCardProps) => {
  const { title, author, date, code, paper, description } = props

  const [isOpenModal, setIsOpenModal] = useState<boolean>(false)
  const [isTextOverflowing, setTextOverflowing] = useState<boolean>(false)
  const overflowingText = useRef<HTMLDivElement | null>(null)

  const checkYOverflow = (textContainer: HTMLElement | null): boolean => {
    if (textContainer) {
      const pElement = textContainer.querySelector('p')
      if (pElement) {
        return textContainer.offsetHeight < pElement.scrollHeight
      }
    }
    return false
  }

  useEffect(() => {
    if (checkYOverflow(overflowingText.current)) {
      setTextOverflowing(true)
    }
  }, [isTextOverflowing])

  return (
    <div className="CommunityCard">
      <div className="CommunityCard__header">
        <h2 className="text-xl">{title}</h2>
      </div>

      <div className="CommunityCardBody">
        <div className="CommunityCardBody__info">
          <div>
            <h3 className="text-base mb-1">{author}</h3>
            <span className="flex items-center">
              <i className="bx bx-time-five text-lg mr-1"></i> {date}
            </span>
          </div>
          <MarkdownText
            text={description}
            ref={overflowingText}
            className={`CommunityCardBody__description ${
              isTextOverflowing && `CommunityCardBody__description--overflow`
            }`}
          />
          {isTextOverflowing && (
            <div className="CommunityCardBody__footer">
              <button
                onClick={() => setIsOpenModal(true)}
                aria-label="Open modal for more information"
              >
                Read more
              </button>
            </div>
          )}
        </div>

        <div className="CommunityCardBody__buttons">
          <CommunityButtons paper={paper} code={code} />
        </div>
      </div>

      <Popup
        modal
        open={isOpenModal}
        closeOnDocumentClick={true}
        closeOnEscape={true}
        lockScroll={true}
        onClose={() => setIsOpenModal(false)}
      >
        <CommunityModal
          setIsOpenModal={setIsOpenModal}
          title={title}
          description={description}
          code={code}
          paper={paper}
        />
      </Popup>
    </div>
  )
}

export default CommunityCard
